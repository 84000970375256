/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeAutoObservable, runInAction } from "mobx";
import { TSignedMessage, TSignMsgWC } from "@/models/types/TSignedMessage";
import AppConnector from "@/crypto/AppConnector";
import * as ethUtil from "ethereumjs-util";
import { useApi } from "@/hooks/useApi";
import { GetWeb3User, ContentType } from "@/api/web3games";

import {
  IFullUser,
  IUserData,
  IWithdraw,
  IFullWallet,
  IGamesList,
  IUserLoginData,
  ILotteryData,
  IRefsData,
  IRefsDataDetailed,
  ISlotsList
} from "@/models/interfaces/IUserStore";
import { randomString } from "@/utils/strings";
import Web3 from "web3";
import { ConnectionStore } from "@/crypto/helpers";

export class UserStore {
  constructor() {
    makeAutoObservable(this);
    this.connectorInit();
    const theme = localStorage.getItem("bitadmin-theme");
    this.toggleTheme(theme === "dark" ? true : false);
  }

  userData: IUserData | null = null;
  referralsList: IRefsData[] = [];
  referralsListDetailed: Record<string, IRefsDataDetailed[]>[] = [];
  withdrawRequests: IWithdraw[] = [];
  banksList: any[] = [];
  lotteriesList: any[] = [];
  usersList: IFullUser[] = [];
  tokenPrice: Record<any, string> = {};
  allUserLottery: ILotteryData = {
    lottery: [],
    lotteryUserData: {
      totalWinsInDollar: "0",
      totalWinsTickets: "0",
      totalTickets: "0"
    }
  };
  slotsUsersList: ISlotsList[] = [];
  // for single user
  allUserSlots: ISlotsList | null = null;
  allUserGames: IGamesList = {
    crash: [],
    limbo: [],
    hashdice: [],
    mines: [],
    plinko: [],
    coinflip: [],
    classicDice: []
  };
  loadedUsersWallets: Record<string, IFullWallet[]>[] = [];
  loadedLoginsData: Record<string, IUserLoginData[]>[] = [];
  isInitialized = false;
  isWalletModalOpen = false;
  connected = false;
  mobileMenuPage = 0;
  themeDark = true;

  setMobileMenuPage = (v: 0 | 1) => (this.mobileMenuPage = v);
  toggleWalletModal = () => (this.isWalletModalOpen = !this.isWalletModalOpen);
  setUserConnected = (payload: boolean) => (this.connected = payload);
  setInitialize = (payload: boolean) => (this.isInitialized = payload);

  loadUserLoginDatas = async (user: string) => {
    const data = useApi();
    try {
      const resp = await data.user.userLogindata(user, {
        withCredentials: true
      });

      console.log(resp, "====resp");

      runInAction(() => {
        if (Object.keys(this.loadedLoginsData).includes(user)) return;

        this.loadedLoginsData.push({
          [user]: resp.data?.data?.loginHistory ?? []
        });
      });
    } catch (err) {
      console.debug(err, "---error loadUserWallets");
      return false;
    }
  };

  loadLotteries = async () => {
    const data = useApi();

    try {
      const lotteries: any = await data.user.adminLotteryall({
        withCredentials: true
      });

      runInAction(() => {
        this.lotteriesList = lotteries?.data?.lotteries?.reverse();
      });
    } catch (e) {
      console.log("user not connected", e);
    }
  };

  loadAllUsers = async () => {
    const data = useApi();

    runInAction(() => {
      this.usersList = [];
    });

    try {
      const users: any = await data.user.adminUsersList({
        withCredentials: true
      });

      runInAction(() => {
        this.usersList = users?.data?.data;
      });
      // if ()
    } catch (e) {
      console.log("user not connected", e);
    }
  };

  loadLotterieSingleData = async (id: string) => {
    const data = useApi();

    try {
      const allLott: any = await data.user.adminLotteryUser(id, {
        withCredentials: true
      });

      console.log(allLott, "---allGames");

      runInAction(() => {
        this.allUserLottery = allLott?.data?.data;
      });
    } catch (e) {
      console.log("user not connected", e);
    }
  };

  reloginUser = async (id: string) => {
    const data = useApi();

    try {
      const allGames: any = await data.user.adminRelogin(id, {
        withCredentials: true
      });

      runInAction(() => {
        this.allUserGames = allGames?.data?.data;
      });
    } catch (e) {
      console.log("user not connected", e);
    }
  };

  loadSlotsForUser = async (id: string) => {
    const data = useApi();

    try {
      const slotsData: any = await data.user.adminSlots(id, {
        withCredentials: true
      });

      runInAction(() => {
        this.allUserSlots = slotsData?.data?.data;
      });
    } catch (e) {
      console.log("user not connected", e);
    }
  };

  loadSlotsUsers = async () => {
    const data = useApi();

    try {
      const slotsData: any = await data.user.adminSlotsList({
        withCredentials: true
      });

      console.log(slotsData, "___slotsData");
      runInAction(() => {
        this.slotsUsersList = slotsData?.data?.data;
      });
    } catch (e) {
      console.log("user not connected", e);
    }
  };

  createAdminComment = async ({ text, date, game, id }: any) => {
    const data = useApi();

    try {
      const allGames: any = await data.user.adminCommentCreate(
        {
          createdAt: date,
          game,
          id,
          text
        },
        {
          withCredentials: true
        }
      );

      runInAction(() => {
        this.allUserGames = allGames?.data?.data;
      });
    } catch (e) {
      console.log("user not connected", e);
    }
  };

  loadGamesForUser = async (id: string) => {
    const data = useApi();

    try {
      const allGames: any = await data.user.adminUserGames(id, {
        withCredentials: true
      });

      runInAction(() => {
        this.allUserGames = allGames?.data?.data;
      });
    } catch (e) {
      console.log("user not connected", e);
    }
  };

  loadSearchUser = async ({ id }: { id: string }) => {
    const data = useApi();

    try {
      const users: any = await data.user.adminUser(id, {
        withCredentials: true
      });

      runInAction(() => {
        this.usersList = users?.data?.data;
      });
      // if ()
    } catch (e) {
      console.log("user not connected", e);
    }
  };

  connectorInit = async () => {
    try {
      await AppConnector.init();
      // if ()
    } catch (e) {
      console.log("user not connected", e);
    }
  };

  toggleTheme = async (val?: boolean) => {
    if (typeof val === "boolean") this.themeDark = val;
    else this.themeDark = !this.themeDark;

    localStorage.setItem("bitadmin-theme", this.themeDark ? "dark" : "light");
  };

  signMessage = async (
    messageToSign: string,
    nonce: string
  ): Promise<TSignedMessage | null> => {
    let web3: any = new Web3(window.ethereum);
    if (!window.ethereum.selectedAddress) {
      await web3.currentProvider.enable();
    }
    const msg = `${Buffer.from(messageToSign, "utf8").toString("hex")}`;
    if (!web3?.currentProvider?.selectedAddress) return null;
    const params = [msg, web3.currentProvider.selectedAddress];

    try {
      const response = await web3.givenProvider.request({
        method: "personal_sign",
        params
      });

      return {
        pubKey: web3.currentProvider.selectedAddress,
        signature: response ?? "",
        message: messageToSign,
        nonce
      };
    } catch (error) {
      return null;
    }
  };

  loadUserWallets = async (user: string) => {
    const data = useApi();
    try {
      const resp = await data.user.allWallets(
        {
          user
        },
        {
          withCredentials: true
        }
      );

      runInAction(() => {
        if (Object.keys(this.loadedUsersWallets).includes(user)) return;

        this.loadedUsersWallets.push({
          [user]: resp.data.wallets
        });
      });
    } catch (err) {
      console.debug(err, "---error loadUserWallets");
      return false;
    }
  };

  submitWithdraw = async (obj: IWithdraw) => {
    const data = useApi();
    try {
      console.log(obj, "obj");
      await data.user.withdrawApprove(
        {
          alias: obj.currency,
          amount: obj.amount,
          address: obj.address,
          txNonce: obj.txNonce,
          status: obj.status,
          chain: obj.chain
        },
        {
          withCredentials: true
        }
      );
    } catch (err) {
      console.debug(err, "---error checkProtected");
      this.setUserConnected(false);
      this.setInitialize(true);
      return false;
    }
  };

  updateVipRef = async (id: string, amount: string) => {
    const data = useApi();
    try {
      await data.user.adminVipUpdate(
        { id, amount: Number(amount) },
        {
          withCredentials: true
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  loadRefferals = async () => {
    const data = useApi();
    try {
      const resp: any = await data.user.adminReferrals({
        withCredentials: true
      });

      if (resp) {
        runInAction(() => {
          this.referralsList = resp?.data?.data;
        });
      }
    } catch (err) {
      console.debug(err, "---error checkProtected");
      this.setUserConnected(false);
      this.setInitialize(true);
      return false;
    }
  };

  loadRefferalsDetailed = async (id: string) => {
    const data = useApi();
    try {
      const resp: any = await data.user.refDetailed(id, {
        withCredentials: true
      });

      if (resp) {
        runInAction(() => {
          if (Object.keys(this.referralsListDetailed).includes(id)) return;

          this.referralsListDetailed.push({
            [id]: resp?.data?.data ?? null
          });
        });
      }
    } catch (err) {
      console.debug(err, "---error checkProtected");
      return false;
    }
  };

  loadBanksGames = async () => {
    const data = useApi();
    try {
      const resp: any = await data.user.adminBanksLoad({
        withCredentials: true
      });

      if (resp) {
        runInAction(() => {
          this.banksList = resp?.data?.data;
          this.tokenPrice = resp?.data?.prices;
        });
      }
    } catch (err) {
      console.debug(err, "---error checkProtected");
      this.setUserConnected(false);
      this.setInitialize(true);
      return false;
    }
  };

  resetBanks = async (
    symbol: string,
    id: string,
    resetAll: boolean,
    isCrash: boolean
  ) => {
    const data = useApi();
    try {
      const resp: any = await data.user.adminBanksReset(
        {
          bankSymbol: symbol,
          bankId: id,
          resetAll,
          isCrash
        },
        {
          withCredentials: true
        }
      );

      if (resp) {
        runInAction(() => {
          this.banksList = resp?.data?.data;
        });
      }
    } catch (err) {
      console.debug(err, "---error checkProtected");
      this.setUserConnected(false);
      this.setInitialize(true);
      return false;
    }
  };

  loadWithdrawRequest = async () => {
    const data = useApi();
    try {
      const resp: any = await data.user.withdrawList({ withCredentials: true });

      if (resp) {
        runInAction(() => {
          this.withdrawRequests = resp?.data?.txs;
        });
      }
    } catch (err) {
      console.debug(err, "---error checkProtected");
      this.setUserConnected(false);
      this.setInitialize(true);
      return false;
    }
  };

  checkProtected = async () => {
    const data = useApi();
    try {
      const resp: any = await data.user.checkAdmin({ withCredentials: true });

      if (resp) {
        runInAction(() => {
          this.userData = resp?.data?.data;
          this.connected = true;
        });
      }
    } catch (err) {
      console.debug(err, "---error checkProtected");
      this.setUserConnected(false);
      this.setInitialize(true);
      return false;
    }
  };

  fullLogout = async () => {
    const data = useApi();
    console.log(ConnectionStore, "fullLogout");

    try {
      if (ConnectionStore) {
        setTimeout(() => {
          ConnectionStore.logOut();
        }, 500);
      }

      return await data.user.logout({ withCredentials: true });
    } catch (err) {
      console.log(err, "---error logout");
      return false;
    }
  };

  signUpUser = async (data: TSignedMessage): Promise<GetWeb3User | any> => {
    const dataApi = useApi();
    console.log(data, "data1");
    if (!data.pubKey || !data.signature || !data.nonce) return false;

    const msgBuffer = Buffer.from(data.message);
    const msgHash = ethUtil.keccak256(msgBuffer);

    const sigParams = ethUtil.fromRpcSig(data.signature);
    const publicKey = ethUtil.ecrecover(
      msgHash, // Notice change here
      sigParams.v,
      sigParams.r,
      sigParams.s
    );
    const sender = ethUtil.publicToAddress(publicKey);
    const senderHex = ethUtil.bufferToHex(sender);
    console.log(senderHex, "senderHex");
    try {
      // with credentials to register cookie
      await dataApi.user.adminLogin(
        {
          walletAddress: data.pubKey,
          walletName: "Metamask",
          walletSign: data.signature,
          nonce: data.nonce,
          signHex: senderHex,
          signMessage: data.message
        },
        { withCredentials: true }
      );
      this.checkProtected();
    } catch (err) {
      console.debug(err, "---error signUpUser");
      return false;
    }
  };

  handleClickMetamask = async () => {
    try {
      const nonce = randomString();
      const sign: TSignedMessage | null = await this.signMessage(
        `Sign in Web3 games, nonce: ${nonce}`,
        nonce
      );
      console.log(sign, "-----SIGN");
      if (sign) await this.signUpUser(sign);
      // handleClose();
    } catch (e) {
      console.log(e);
    }
  };
}
